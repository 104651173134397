<script>
	import Modal from 'svelte-simple-modal'
	import { l10n } from './i18n/i18n.js'
	import Content from './components/Content.svelte'
</script>

<svelte:head>
  <title>{$l10n('title')}</title>
	<meta name="robots" content="noindex">
</svelte:head>

<Modal>
	<Content />
</Modal>

<style lang="scss">
	@import './App.scss';
</style>