<script>
  let params = new URLSearchParams(window.location.search);

	import { Button, Input } from 'svelma'
	import { getContext } from 'svelte';
	import { firebaseAuth, firebaseDb, firebaseFirestore, firebaseAnalytics, firebaseFunctions } from "../firebase.js";
	import { l10n } from '../i18n/i18n.js'
  import Popup from './Popup.svelte';
  import delay from '../delay.js'
	export var email = params.get("email") || '';
	export var password = "";
	export let emailMessage = "";
	export let emailMessageHidden = true;
	export let passwordMessage = "";
	export let passwordMessageHidden = true;
	export var promo = params.get("code") || '';
	export let promoMessage = "";
	export let promoMessageHidden = true;
	export let action = null;
	export let done = false;
	export let signedin = false;
	export let resetPassword = false;
	export let urlRef = params.get("ref") || '';
	export var congratsMessage = $l10n('form.youveActivated');
	var firstName;
	var lastName;

	const { open } = getContext('simple-modal');
	const addCodeMembership = firebaseFunctions.httpsCallable('addCodeMembership');

 
	$: emailMessageHidden = !emailMessage
	$: passwordMessageHidden = !passwordMessage
	$: promoMessageHidden = !promoMessage
	$: isPerformingAction = !!action

	console.log("REF", urlRef);

	const emailChanged = function() {
		var changeCounter = 0;
		return async (event) => {
			resetPassword = false;
		
			let count = ++changeCounter;
			let curVal = emailMessage;
			await delay(1000);
			if (changeCounter == count && curVal == emailMessage) {
				emailMessage = event.detail.target.validationMessage;
			}
		}
	}();

	const passwordChanged = function() {
		var changeCounter = 0;
		return async (event) => {
			resetPassword = false;
		
			let count = ++changeCounter;
			let curVal = passwordMessage;
			let newMessage = event.detail.target.validationMessage || (event.detail.target.value.length < 6 ? $l10n('form.errors.passwordAtLeastSix') : '');
			await delay(1000);
			if (changeCounter == count && curVal == passwordMessage) {
				passwordMessage = newMessage;
			}
		}
	}();

	const ndtd= '[^0-9A-Za-z]*([0-9A-Za-z])?';
	const promoRegex = RegExp(`^${ndtd}${ndtd}${ndtd}${ndtd}${ndtd}${ndtd}${ndtd}${ndtd}${ndtd}`);

	const promoChanged = function(e) {
		promoMessage = '';
		var p = promo.toUpperCase();

		var matches = promoRegex.exec(p);

		matches.shift(1);

		p = matches.map((v) => v || '').join('');


		promo = (p.substr(0, 3) + ' ' + p.substr(3, 3) + ' ' + p.substr(6, 3)).trimEnd();

		if (!e) return 0;

		let selectionStart = e.detail.target.selectionStart;
		if (selectionStart == 4 ||  selectionStart == 8) return 1;
		return 0;
	}

	promoChanged();

	const preserveCursor = (fn) => {
		return function(e) {
			const target = e.detail.target;
			const start = target.selectionStart;
			const end = target.selectionEnd;
			
			const add = fn(e);

			requestAnimationFrame(() => {
				target.selectionStart = start + add;
				target.selectionEnd = end + add;
			});
		}
	}

	async function handleSubmit() {
		//TODO: remove this

		console.log("Called!");

		if (resetPassword) {
			resetPassword = false;

			firebaseAuth.sendPasswordResetEmail(email);
			emailMessage = $l10n('form.errors.sentResetEmail')
			passwordMessage = null;

			firebaseAnalytics.logEvent("password_reset", {});
			return;
		}

		if (firebaseAuth.currentUser) {
			try{
				await firebaseAuth.signOut();
			} catch (e) {}
		}

		emailMessage = null
		passwordMessage = null

		action = "Registering";
		let authenticated = await performAuthentication();
		action = null;

		if (!authenticated) {
			action = null;
			return;
		}

		let user = firebaseAuth.currentUser;

		if (!user) {
			action = null;
			passwordMessage = $l10n('form.errors.problemLoggingIn');
			return;
		}

		firebaseAnalytics.setUserId(user.uid);

		signedin = true;
		action = null;
	}

	async function handleCodeSubmit() {
		
		let promoNoSpaces = promo.replace(/ /g, '');

		if (promoNoSpaces.length < 9) {
			promoMessage = $l10n('form.errors.promoCodeNine');
			return;
		}

		action = "Setting up Premium";

		try {
			console.log("Trying");
			var result = await addCodeMembership({code: promoNoSpaces, ref: urlRef});
			console.log("Done.", result);

			let {
				expiry,
				ref,
				newuser,
				extended,
				duration
			} = result.data;

			console.log("Using duration " + duration);

			switch(duration) {
				case "oneyear":
					congratsMessage = $l10n('form.youveActivatedOneYear');;
					break;
				case "twoyear":
					congratsMessage = $l10n('form.youveActivatedTwoYear');;
					break;
				case "lifetime":
					congratsMessage = $l10n('form.youveActivatedLifetime');;
					break;
				default:
					congratsMessage = $l10n('form.youveActivated');
			}

			done = true;

			firebaseAnalytics.setUserProperties({plan: "promo", plan_specifics: ref});
			firebaseAnalytics.logEvent("use_promo", {
				promo: ref,
				app_version: "flutter",
				new_user: newuser,
				extended: extended,
				duration: duration,
			});		
		} catch (error) {
			var code = error.code;
			var message = error.message;
			var details = error.details;
			console.log("Error!", code, message, details);

			if (error.code == "unauthenticated") {
				// not authenticated.
				signedin = false;
			} else if (error.code == 'not-found') {
				// code invalid or nonexistent
				promoMessage = $l10n('form.errors.promoCodeInvalid');
				firebaseAnalytics.logEvent("promo_code_invalid", {
					code: promo
				});
			} else if (error.code == 'already-exists') {
				// code has already been used
				promoMessage = $l10n('form.errors.promoCodeAlreadyUsed');
				firebaseAnalytics.logEvent("promo_code_already_used", {
					code: promo
				});
			} else {
				promoMessage = $l10n('form.errors.errorPromoCode');
			}
		} finally {
			console.log("Action done");
			action = null;
		}
	}

	async function performAuthentication() {
		try {
			await firebaseAuth.createUserWithEmailAndPassword(email, password);
			firebaseAnalytics.logEvent(firebase.analytics.EventName.SIGN_UP, {
				method: "emailpassword",
			});
			return true;
		} catch (error) {
			let errorCode = error.code;
			let errorMessage = error.message;

			if (errorCode === "auth/email-already-in-use") {

				try {
					await firebaseAuth.signInWithEmailAndPassword(email, password);
					firebaseAnalytics.logEvent(firebase.analytics.EventName.LOGIN, {
						method: "emailpassword",
					});
					return true;
				} catch(error) {
					errorCode = error.code;
					errorMessage = error.message;

					if (errorCode === "auth/invalid-email") {
						emailMessage = $l10n('form.errors.emailInvalid')
					} else if (errorCode === "auth/user-disabled") {
						emailMessage = $l10n('form.errors.unknown')
					} else if (errorCode === "auth/user-not-found") {
						emailMessage = $l10n('form.errors.unknown')
					} else if (errorCode === "auth/wrong-password") {
						emailMessage = $l10n('form.errors.emailInUse')
						passwordMessage = $l10n('form.errors.passwordIncorrect');
						resetPassword = true;
					}
				}

			} else if (errorCode === "auth/invalid-email") {
					emailMessage = $l10n('form.errors.emailInvalid')
			} else if (errorCode === "auth/weak-password") {
					passwordMessage = $l10n('form.errors.passwordNotStrong');
			}
			return false;
		}
	}
</script>

<main class="ron-bg">
  <div class="top flex col" >
    <div class="imgs">
      <div class="rootd">
        <a href="https://www.rootd.io" target="_blank">
          <img src="rootd_white.png" alt="Rootd"/>
        </a>
      </div>
			<!-- <div class="topright">
        <a href="https://www.rootd.io" target="_blank">
          <img src="rootd_white.png" alt="Rootd"/>
        </a>
      </div> -->
    </div>
    <div class="title">{$l10n('top.title')}</div>
    <div class="form">
      {#if !done}
			 {#if !signedin}
					<form class="flex col" on:submit|preventDefault="{handleSubmit}">
						<Input type="email" placeholder="{$l10n('form.emailAddress')}" class="input is-rounded placeholder-center text-center" variant="outlined" bind:value={email} autocomplete="email" on:input={emailChanged} required/> 
						<div class="helpertext" class:vis-hidden={emailMessageHidden}>&nbsp;{emailMessage}&nbsp;</div>
						<Input type="password" placeholder="{$l10n('form.password')}" class="input is-rounded placeholder-center text-center" variant="outlined" bind:value={password} autocomplete="password" minlength="6" on:input={passwordChanged} required />
						<div class="helpertext" class:vis-hidden={passwordMessageHidden}>&nbsp;{passwordMessage}&nbsp;</div>
						<Button rounded nativeType=submit loading={isPerformingAction || undefined} class="button is-primary">{#if resetPassword}{$l10n('form.resetPassword')}{:else}{$l10n('form.register')}{/if}</Button>
					</form>
				{:else}
				  <form class="flex col" on:submit|preventDefault="{handleCodeSubmit}">
						<Input type="text" placeholder="{$l10n('form.promoCode')}" class="input is-rounded placeholder-center text-center" variant="outlined" bind:value={promo} autocomplete="off" on:input={preserveCursor(promoChanged)} required/> 
						<div class="helpertext" class:vis-hidden={promoMessageHidden}>&nbsp;{promoMessage}&nbsp;</div>
						<Button rounded nativeType=submit loading={isPerformingAction || undefined} class="button is-primary">{$l10n('form.submitCode')}</Button>
					</form>
				{/if}
      {:else}
        <div class="congrats">
          {$l10n('form.congrats')}<br>
					{congratsMessage}
        </div>
        <div class="subtitle">
          {$l10n('form.toGetStarted')}
        </div>
        <div class="storelinks">
          <a href="https://apple.co/2z9KsQW" target="_blank">
            <img src="downloadappstore.png" alt="{$l10n('form.downloadAppStoreAlt')}">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.rootd" target="_blank">
            <img src="downloadgoogleplay.png" alt="{$l10n('form.downloadPlayStoreAlt')}">
          </a>
        </div>
      {/if}
    </div>
		<div class="social">
			<a href="https://www.facebook.com/rootdapp" target="_blank">
				<img src="facebook.png" alt="facebook"/>
			</a>
			<a href="https://twitter.com/rootd_app" target="_blank">
				<img src="instagram.png" alt="instagram"/>
			</a>
			<a href="https://instagram.com/rootd_app" target="_blank">
				<img src="twitter.png" alt="twitter"/>
			</a>
		</div>
  </div>
</main>


<style lang="scss">


	main {
		min-height: 100vh;
	}

	.ron-bg {
		background-image: url(ron_bench.jpg), url(ron_bench_1px.jpg);
		background-repeat: no-repeat, repeat-x;
		background-position: center;
		background-attachment: fixed;
 	  background-size: contain;
	}

	@media only screen and (max-width: 1200px) {
		.ron-bg {
			background-size: cover;
		}
	}

	.imgs {
		display: flex;
	}

	.top {
		padding-bottom: 30px;
	}

	.top .rootd {
		text-align: left;
	}

	.top .topright {
		text-align: right;
	}

	.top .rootd, .top .topright {
		z-index: 1;
		transition: text-align .3s;
		width: 50%;
		align-self: center;
	}

	.imgs {
		padding: 30px;		
	}

	.top .rootd img {
		height: 60px;
	} 
	
	.top .topright img  {
		height: 40px;
	}

	@media only screen and (max-width: 650px) {
		.imgs {
			display: initial;
		}

		.top .rootd, .top .topright {
			text-align: center;
			width: 100%;
		}
	}

	.top .title {
		max-width: 600px;
		margin: 0 auto;
		padding: 30px;
	}

	.helpertext {
		color: #F00;
	}

	.vis-hidden {
		visibility: hidden;
	}

	.top .title {
		font-size: 32px;
	}

	.form {
		transition: height 0.3s;
	}

	.form form {
		align-items: center;
		justify-items: center;
	}

	.form :global(.input) {
		font-family: inherit !important;
		width: 300px;
		font-size: 22px;
	}
	
	.form :global(.input)::placeholder {
		color: #9a9a9a;
	}
	

	.form :global(.button) {
		font-family: inherit !important;
		font-size: 22px;
		width: 200px;
	}

	.form .congrats {
		font-size: 28px;
		margin-bottom: 20px;
	}

	.form .subtitle {
		font-size: 24px;
		margin-bottom: 20px;
	}

	.form .storelinks>a {
		margin: 0 10px;
	}

	.form .storelinks {
		margin-bottom: 10px;
	}

	.social {
		position: relative;
		bottom: -20px;
		left: 0;
		right: 0;
		padding: 0;
	}

	.social img {
		height: 40px;
		margin: 0 10px;
	}

	.social img:hover {
		filter: brightness(95%);
		transition: all .3s;
	}

	.recs>*, .form>* {
		margin: 0 auto; 
	}

	.recs>* {
		max-width: 800px;
	}

	.form>* {
		max-width: 800px;
	}

	.rootdinfo>* {
		max-width: 1024px;
		margin-left: auto;
		margin-right: auto;
	}

</style>